import React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import { NavLink } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { logout } from "../../state/AppState";

class Nav extends React.Component<Props> {
  handleLogoutPress() {
    if (window.confirm("Are you sure you want to logout?")) {
      this.props.logout();
    }
  }

  render() {
    if (!this.props.isLoggedIn) {
      return null;
    }

    return (
      <>
        <nav className="Nav">
          <NavLink to={`/${this.props.uuid}/home`} activeClassName="active">
            <FontAwesomeIcon icon="home" />
          </NavLink>
          <NavLink to={`/${this.props.uuid}/stories`} activeClassName="active">
            <FontAwesomeIcon icon="book" />
          </NavLink>
          <NavLink to={`/${this.props.uuid}/map`} activeClassName="active">
            <FontAwesomeIcon icon="map" />
          </NavLink>
          <a
            onClick={this.handleLogoutPress.bind(this)}
            className="flipIconHorizontally"
          >
            <FontAwesomeIcon icon="sign-out-alt" />
          </a>
        </nav>
      </>
    );
  }
}

interface StateProps {
  isLoggedIn: boolean;
  uuid: string;
}

interface DispatchProps {
  logout: typeof logout;
}

interface Props extends StateProps, DispatchProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  isLoggedIn: state.app.isLoggedIn,
  uuid: state.app.uuid
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators(
    {
      logout
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Nav);
