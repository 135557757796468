import React from "react";
import { Route, Redirect } from "react-router-dom";

const NonAuthorizedRoute = ({ authorized, ...props }) => {
  const uuid = props.computedMatch.params.uuid;
  const redirectUrl = uuid ? `/${uuid}/home` : "/";
  return !authorized ? <Route {...props} /> : <Redirect to={redirectUrl} />;
};

export default NonAuthorizedRoute;
