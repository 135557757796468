import React from "react";
import { Link } from "react-router-dom";

interface Props {
  title: string;
  onPress(): any;
  linkTo: string;
  style: any;
}

class Button extends React.Component<Props> {
  static defaultProps = {
    title: null,
    onPress: () => {},
    linkTo: null,
    style: {}
  };

  handleClick() {
    if (this.props.linkTo) {
      window.location.pathname = this.props.linkTo;
    } else if (this.props.onPress) {
      this.props.onPress();
    }
  }

  render() {
    if (this.props.linkTo) {
      return (
        <Link
          className="Button"
          to={this.props.linkTo}
          style={this.props.style}
        >
          {this.props.children}
        </Link>
      );
    }

    return (
      <div
        className="Button"
        onClick={this.handleClick.bind(this)}
        style={this.props.style}
      >
        {this.props.children}
      </div>
    );
  }
}

export { Button };
