import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  visible: boolean;
  onClosePress: any;
  position: "left" | "right";
}

class Tray extends React.Component<Props> {
  static defaultProps = {
    visible: false,
    onClosePress: () => {},
    position: "right"
  };

  render() {
    return (
      <div
        className={`Tray Tray_${this.props.position} ${
          this.props.visible ? "visible" : ""
        }`}
      >
        <div
          className="Tray__close"
          onClick={this.props.onClosePress.bind(this)}
        >
          <FontAwesomeIcon
            icon={
              this.props.position === "right" ? "chevron-right" : "chevron-left"
            }
          />
        </div>
        <div className="Tray__content">{this.props.children}</div>
      </div>
    );
  }
}

export { Tray };
