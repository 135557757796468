import React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import { fetchCampaign } from "../../state/CampaignState";
import { WidgetContainer, Map, Tray, Button, BasicChart } from "../common";
import { CampaignProps, StoryProps, UserProps } from "../../state/Props";
import { Flex, Box } from "@rebass/grid";
import { languageCodeToName, staticMapUrl } from "../../services/Utils";
import { environment, colors } from "../../config";
import { setUuid } from "../../state/AppState";
import { NavProps } from "../../state/Props";

interface ComponentState {
  trayVisible: boolean;
  selectedStory: StoryProps | null;
  selectedUsers: UserProps[] | null;
  showingUsers: boolean;
}

class MapScreen extends React.Component<Props> {
  state: ComponentState = {
    trayVisible: false,
    selectedStory: null,
    showingUsers: false,
    selectedUsers: null
  };

  componentWillMount() {
    const uuid = this.props.match.params.uuid;
    this.props.setUuid(uuid);

    this.props.fetchCampaign(uuid, this.props.password);
  }

  handleMarkerClick(story: StoryProps) {
    this.setState({
      trayVisible: true,
      selectedStory: story,
      showingUsers: false
    });
  }
  handleUserMarkerClick(user: UserProps) {
    this.setState({
      trayVisible: true,
      selectedUsers: [user],
      showingUsers: true
    });
  }

  handleClusterUserClick(event: any) {
    const markers = event.getMarkers();

    let users = [];
    let userIds: any[] = [];
    for (let marker of markers) {
      if (!userIds.includes(marker.user.id)) {
        users.push(marker.user);
        userIds.push(marker.user.id);
      }
    }

    console.log("USERS", users);

    this.setState({
      trayVisible: true,
      selectedUsers: users,
      showingUsers: true
    });
  }

  handleViewPress(url: string) {
    window.open(
      `${environment.baseUrl}${url}?passcode=${environment.permalinkPasscode}`.replace(
        ".com//",
        ".com/"
      )
    );
  }

  renderTrayContentUser() {
    const users = this.state.selectedUsers;
    if (!users) {
      return;
    }

    return users.map(user => {
      return (
        <WidgetContainer
          key={user.id}
          title={user.username}
          subtitle={user.location ? user.location.name : null}
        >
          <div style={{ textAlign: "left" }} />
          <hr />
          <img src={user.image} width="100%" style={{ borderRadius: 5 }} />
        </WidgetContainer>
      );
    });
  }

  renderTrayContentStory() {
    const story = this.state.selectedStory;
    if (!story) {
      return;
    }
    return (
      <>
        <WidgetContainer title={story.title} subtitle={story.category.name}>
          <div style={{ textAlign: "left" }}>
            <Button onPress={this.handleViewPress.bind(this, story.permalink)}>
              View Story
            </Button>
          </div>
          <hr />
          <img src={story.image} width="100%" style={{ borderRadius: 5 }} />
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Author:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.owner.username}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Category:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.category.name}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Location:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.location ? story.location.name : "N/A"}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Language:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {languageCodeToName(story.language)}
            </Box>
          </Flex>
        </WidgetContainer>
        <WidgetContainer title="Stats" subtitle={""}>
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Views:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.views_count}
            </Box>
          </Flex>
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Likes:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.likes_count}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Bookmarks:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.bookmarks_count}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Comments:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.comments_count}
            </Box>
          </Flex>
        </WidgetContainer>

        <WidgetContainer title="Activity">
          {this.renderActivityChart(story)}
        </WidgetContainer>

        {story.location ? (
          <WidgetContainer
            title="Location"
            subtitle={story.location ? story.location.name : "N/A"}
          >
            <img
              src={staticMapUrl(
                story.location.latitude,
                story.location.longitude
              )}
              width="100%"
            />
          </WidgetContainer>
        ) : null}
      </>
    );
  }

  renderActivityChart(story: StoryProps) {
    const likes = this.props.campaign.likes.filter(item => {
      return item.target === story.id;
    });

    const bookmarks = this.props.campaign.bookmarks.filter(item => {
      return item.target === story.id;
    });

    const comments = this.props.campaign.comments.filter(item => {
      return item.story === story.id;
    });

    const views = this.props.campaign.views.filter(item => {
      return item.target === story.id;
    });

    const headingStyle = {
      fontSize: 12,
      color: colors.primary,
      marginBottom: 12
    };

    return (
      <>
        <p style={headingStyle}>Views</p>
        <BasicChart entries={views} showLegend={false} />
        <p style={headingStyle}>Likes</p>
        <BasicChart entries={likes} showLegend={false} />
        <p style={headingStyle}>Comments</p>
        <BasicChart entries={comments} showLegend={false} />
        <p style={headingStyle}>Bookmarks</p>
        <BasicChart entries={bookmarks} showLegend={false} />
      </>
    );
  }

  renderTray() {
    return (
      <Tray
        visible={this.state.trayVisible}
        onClosePress={() => this.setState({ trayVisible: false })}
        position={"right"}
      >
        {this.state.showingUsers
          ? this.renderTrayContentUser()
          : this.renderTrayContentStory()}
      </Tray>
    );
  }

  render() {
    if (!this.props.campaign) {
      return null;
    }

    const storyMarkers = this.props.campaign.collection.stories
      .filter(story => {
        return story.location;
      })
      .map(story => {
        const location = story.location;

        return {
          lat: location.latitude,
          lng: location.longitude,
          onClick: this.handleMarkerClick.bind(this, story),
          story: story
        };
      });

    const userMarkers = this.props.campaign.collection.stories
      .filter(story => {
        return story.owner.location;
      })
      .map(story => {
        const location = story.owner.location;
        return {
          lat: location.latitude,
          lng: location.longitude,
          onClick: this.handleUserMarkerClick.bind(this, story.owner),
          user: story.owner
        };
      });

    const userMarkersUnique = [];
    const userMarkerIds: any = [];
    for (let s of userMarkers) {
      if (!userMarkerIds.includes(s.user.id)) {
        userMarkerIds.push(s.user.id);
        userMarkersUnique.push(s);
      }
    }

    return (
      <div className="screen MapScreen">
        {storyMarkers.length ? (
          <WidgetContainer title="Map" subtitle="Stories">
            <Map
              markers={storyMarkers}
              defaultCenter={storyMarkers[0]}
              onClusterClick={this.handleClusterUserClick.bind(this)}
            />
          </WidgetContainer>
        ) : null}
        {userMarkersUnique.length ? (
          <WidgetContainer title="Map" subtitle="Users">
            <Map
              markers={userMarkersUnique}
              defaultCenter={userMarkersUnique[0]}
              onClusterClick={this.handleClusterUserClick.bind(this)}
            />
          </WidgetContainer>
        ) : null}
        {this.renderTray()}
      </div>
    );
  }
}

interface StateProps {
  campaign: CampaignProps;
  trayVisible: boolean;
  selectedStory: StoryProps;
  password: string;
}

interface DispatchProps {
  fetchCampaign: typeof fetchCampaign;
  setUuid: typeof setUuid;
}

interface Props extends StateProps, DispatchProps, NavProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  campaign: state.campaign.data,
  password: state.app.password
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators({ fetchCampaign, setUuid }, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MapScreen);
