import React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import { login, setUuid } from "../../state/AppState";
import { Button, WidgetContainer } from "../common";

import { TextInput } from "../common/forms";
import { NavProps } from "../../state/Props";
import { colors } from "../../config";

class LoginScreen extends React.Component<Props> {
  state = {
    passwordText: ""
  };

  componentWillMount() {
    const store = window.localStorage.getItem("store");
    if (store) {
      const obj = JSON.parse(store);
      if (obj && obj.uuid == this.props.match.params.uuid) {
        this.props.login(obj.uuid, obj.password);
      }
    }
  }
  handleLoginPress() {
    const uuid = this.props.match.params.uuid;

    this.props.login(uuid, this.state.passwordText);
    this.props.setUuid(uuid);
  }
  render() {
    return (
      <div className="LoginScreen">
        <WidgetContainer title="Login" fillHeight={false}>
          <p
            style={{
              color: colors.error,
              fontSize: 12,
              paddingTop: 8,
              paddingBottom: 8
            }}
          >
            {this.props.errorMessage}
          </p>
          <TextInput
            placeholder="Password"
            secure
            initialValue={this.state.passwordText}
            onChange={val => this.setState({ passwordText: val })}
            onKeyPress={(event: any) => {
              if (event.key === "Enter") {
                this.handleLoginPress();
              }
            }}
          />
          <hr />
          <Button onPress={this.handleLoginPress.bind(this)}>Login</Button>
        </WidgetContainer>
      </div>
    );
  }
}

interface StateProps {
  passwordText: string;
  errorMessage: string;
}

interface DispatchProps {
  login: typeof login;
  setUuid: typeof setUuid;
}

interface Props extends StateProps, DispatchProps, NavProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  loggedIn: state.app.loggedIn,
  errorMessage: state.app.errorMessage
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators(
    {
      login,
      setUuid
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginScreen);
