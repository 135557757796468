import React from "react";
import { FilterProps } from "../../state/Props";

interface Props {
  headers: string[];
  rows: any[];
  sortableColumnIndexes: number[];
  filterOptions: FilterProps[] | null;
  maxHeight: number | null;
}

interface StateProps {
  rows: string[];
  sortingDirection: number | null;
  sortingByIndex: number | null;
  filterByIndex: number | null;
  filterByValue: string | null;
}

class Table extends React.Component<Props, StateProps> {
  static defaultProps = {
    headers: [],
    rows: [],
    sortableColumnIndexes: [],
    filterOptions: [],
    maxHeight: null
  };

  state = {
    rows: [],
    sortingByIndex: null,
    sortingDirection: 0,
    filterByIndex: null,
    filterByValue: null
  };

  componentWillMount() {
    this.setState({
      rows: this.props.rows
    });
  }

  handleSort(columnIndex: number | null) {
    if (columnIndex === null) {
      this.setState({
        rows: this.props.rows,
        sortingByIndex: null,
        sortingDirection: 0
      });

      return;
    }

    let dir: number = this.state.sortingDirection;
    if (dir) {
      dir = dir * -1;
    } else {
      dir = -1;
    }

    const updatedRows = this.state.rows.sort((a: any, b: any) => {
      return a[columnIndex] >= b[columnIndex] ? dir : dir * -1;
    });

    this.setState({
      rows: updatedRows,
      sortingDirection: dir,
      sortingByIndex: columnIndex
    });
  }

  handleFilterChange(filter: FilterProps, event: any) {
    const value = event.target.value;
    if (value === "default") {
      this.setState({ filterByIndex: null, filterByValue: null });
      return;
    }

    this.setState({ filterByIndex: filter.columnIndex, filterByValue: value });
  }

  renderHeaders() {
    return (
      <tr>
        {this.props.headers.map((header, index) => {
          const isSortable = this.props.sortableColumnIndexes.includes(index);
          const className = [
            isSortable ? "sortable" : "",
            index === this.state.sortingByIndex
              ? `sorting ${
                  this.state.sortingDirection === 1
                    ? "sorting__up"
                    : "sorting__down"
                }`
              : ""
          ].join(" ");
          return (
            <th
              key={index}
              onClick={
                isSortable ? this.handleSort.bind(this, index) : () => {}
              }
              className={className}
            >
              {header}
            </th>
          );
        })}
      </tr>
    );
  }

  renderRows() {
    let rows = this.state.rows;
    if (
      this.state.filterByIndex !== null &&
      this.state.filterByValue !== null
    ) {
      rows = rows.filter((col, i) => {
        return col[this.state.filterByIndex || 0] === this.state.filterByValue;
      });
    }

    return rows.map((row: any, index) => {
      return (
        <tr key={index}>
          {row.map((col: any, index: number) => {
            return <td key={index}>{col}</td>;
          })}
        </tr>
      );
    });
  }

  renderTableActionClearSorting() {
    if (this.state.sortingByIndex !== null) {
      return (
        <li>
          <a href="#" onClick={this.handleSort.bind(this, null)}>
            clear sorting
          </a>
        </li>
      );
    }
  }

  renderFilters() {
    if (this.props.filterOptions && this.props.filterOptions.length) {
      const filters = this.props.filterOptions;
      return filters.map(f => {
        const selectedValue: any =
          this.state.filterByIndex === f.columnIndex
            ? this.state.filterByValue
            : "default";
        return (
          <li>
            <span>Filter By {f.name}: </span>
            <select
              value={selectedValue}
              onChange={this.handleFilterChange.bind(this, f)}
            >
              <option value="default">---</option>
              {f.options.map(o => {
                return <option value={o}>{o}</option>;
              })}
            </select>
          </li>
        );
      });
    }

    return null;
  }

  renderTableActions() {
    return (
      <ul className={"table-actions"}>
        {this.renderTableActionClearSorting()}
        {this.renderFilters()}
      </ul>
    );
  }

  render() {
    const style: any = this.props.maxHeight
      ? {
          maxHeight: this.props.maxHeight,
          overflowY: "auto",
          display: "block"
        }
      : {};
    return (
      <table className="Table" style={style}>
        {this.renderTableActions()}
        <thead>{this.renderHeaders()}</thead>
        <tbody>{this.renderRows()}</tbody>
      </table>
    );
  }
}

export { Table };
