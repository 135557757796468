import React from "react"
import * as Redux from "redux"
import { connect } from "react-redux"
import { Flex, Box } from "@rebass/grid"

import { fetchCampaign } from "../../state/CampaignState"
import { setUuid } from "../../state/AppState"
import { WidgetContainer, Table, Button, Tray, BasicChart } from "../common"
import { CampaignProps, StoryProps } from "../../state/Props"
import { environment, colors } from "../../config"
import { languageCodeToName, staticMapUrl } from "../../services/Utils"
import { NavProps, FilterProps } from "../../state/Props"
import { array } from "prop-types"

interface ComponentState {
  trayVisible: boolean
  selectedStory: StoryProps | null
}

class StoryListScreen extends React.Component<Props> {
  state: ComponentState = {
    trayVisible: false,
    selectedStory: null,
  }

  componentWillMount() {
    const uuid = this.props.match.params.uuid
    this.props.setUuid(uuid)

    this.props.fetchCampaign(uuid, this.props.password)
  }

  handleViewPress(url: string) {
    window.open(`https://storyplace.com${url}`)
  }

  handleMoreInfoPress(story: StoryProps) {
    this.setState({ trayVisible: true, selectedStory: story })
  }

  renderInfo(story: StoryProps) {
    return (
      <div className="storyInfo">
        <span>Title:</span> {story.title} <br />
        <span>Author:</span> {story.owner.username} <br />
        <span>Category:</span> {story.category.name} <br />
        <span>Location:</span> {story.location ? story.location.name : "-"}
      </div>
    )
  }

  renderTable() {
    if (!this.props.campaign || !this.props.campaign.collection.stories) {
      return null
    }

    const tableHeaders = [
      "Image",
      "Info",
      "Language",
      "Location",
      "Views",
      "Likes",
      "Bookmarks",
      "Actions",
    ]

    const sortableColumnIndexes = [2, 3, 4, 5, 6]

    const tableRows = this.props.campaign.collection.stories.map((story) => {
      const row: any[] = [
        <div
          className="imageThumbnail"
          style={{
            backgroundImage: `url(${story.image})`,
            width: 64,
            height: 64,
            backgroundSize: "cover",
          }}
        />,
        this.renderInfo(story),
        story.language === "es" ? "Spanish" : "English",
        story.location ? story.location.name : "-",
        story.views_count,
        story.likes_count,
        story.bookmarks_count,

        <Button onPress={this.handleMoreInfoPress.bind(this, story)}>
          View Story
        </Button>,
      ]
      return row
    })

    const filterOptions: FilterProps[] = [
      {
        columnIndex: 2,
        name: "Language",
        options: ["English", "Spanish"],
      },
      {
        columnIndex: 3,
        name: "Location",
        options: this.props.campaign.collection.stories
          .map((story) => {
            return story.location ? story.location.name : "None"
          })
          .filter((s) => {
            return s !== "None"
          })
          .reduce(
            (unique: any, item: any) =>
              unique.includes(item) ? unique : [...unique, item],
            []
          )
          .sort((a: string, b: string) => {
            if (b === "No Location") {
              return 1
            }

            return a >= b ? 1 : -1
          }),
      },
    ]

    return (
      <Table
        headers={tableHeaders}
        rows={tableRows}
        sortableColumnIndexes={sortableColumnIndexes}
        filterOptions={filterOptions}
      />
    )
  }

  renderTray() {
    return (
      <Tray
        visible={this.state.trayVisible}
        onClosePress={() => this.setState({ trayVisible: false })}
        position={"right"}
      >
        {this.renderTrayContent()}
      </Tray>
    )
  }

  renderTrayContent() {
    const story = this.state.selectedStory
    if (!story) {
      return
    }

    return (
      <>
        <WidgetContainer title={story.title} subtitle={story.category.name}>
          <div style={{ textAlign: "left" }}>
            <Button onPress={this.handleViewPress.bind(this, story.permalink)}>
              Read Story
            </Button>
          </div>
          <hr />
          <img src={story.image} width="100%" style={{ borderRadius: 5 }} />
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Author:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.owner.username}
            </Box>
          </Flex>
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Category:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.category.name}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Location:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.location ? story.location.name : "N/A"}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Language:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {languageCodeToName(story.language)}
            </Box>
          </Flex>
        </WidgetContainer>
        <WidgetContainer title="Stats" subtitle={""}>
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Views:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.views_count}
            </Box>
          </Flex>
          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Likes:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.likes_count}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Bookmarks:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.bookmarks_count}
            </Box>
          </Flex>

          <Flex className="listItem">
            <Box className="listItemName" width={1 / 2} px={2}>
              Comments:
            </Box>
            <Box className="listItemValue" width={1 / 2} px={2}>
              {story.comments_count}
            </Box>
          </Flex>
        </WidgetContainer>

        <WidgetContainer title="Activity">
          {this.renderActivityChart(story)}
        </WidgetContainer>
        {story.location ? (
          <WidgetContainer
            title="Location"
            subtitle={story.location ? story.location.name : "N/A"}
          >
            <img
              src={staticMapUrl(
                story.location.latitude,
                story.location.longitude
              )}
              width="100%"
            />
          </WidgetContainer>
        ) : null}
      </>
    )
  }

  renderActivityChart(story: StoryProps) {
    const likes = this.props.campaign.likes.filter((item) => {
      return item.target === story.id
    })

    const bookmarks = this.props.campaign.bookmarks.filter((item) => {
      return item.target === story.id
    })

    const comments = this.props.campaign.comments.filter((item) => {
      return item.story === story.id
    })

    const views = this.props.campaign.views.filter((item) => {
      return item.target === story.id
    })

    const headingStyle = {
      fontSize: 12,
      color: colors.primary,
      marginBottom: 12,
    }

    return (
      <>
        <p style={headingStyle}>Views</p>
        <BasicChart entries={views} showLegend={false} />
        <p style={headingStyle}>Likes</p>
        <BasicChart entries={likes} showLegend={false} />
        <p style={headingStyle}>Comments</p>
        <BasicChart entries={comments} showLegend={false} />
        <p style={headingStyle}>Bookmarks</p>
        <BasicChart entries={bookmarks} showLegend={false} />
      </>
    )
  }

  render() {
    if (!this.props.campaign) {
      return null
    }
    return (
      <div className="screen StoryListScreen">
        <WidgetContainer
          title={`Stories: ${this.props.campaign.collection.name}`}
        >
          {this.renderTable()}
        </WidgetContainer>
        {this.renderTray()}
      </div>
    )
  }
}

interface StateProps {
  campaign: CampaignProps
  password: string
}

interface DispatchProps {
  fetchCampaign: typeof fetchCampaign
  setUuid: typeof setUuid
}

interface Props extends StateProps, DispatchProps, NavProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  campaign: state.campaign.data,
  password: state.app.password,
})

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators({ fetchCampaign, setUuid }, dispatch),
})

export default connect(mapStateToProps, mapDispatchToProps)(StoryListScreen)
