import React from 'react';
import * as Redux from 'redux';
import { connect } from 'react-redux';

import { Flex, Box } from '@rebass/grid';

import { CampaignProps } from '../../state/Props';

class BreadCrumbs extends React.Component<Props> {
  render() {
    if (!this.props.campaign) {
      return null;
    }

    console.log(this.props.campaign);
    return (
      <Flex>
        <Box width={1} px={2}>
          <div className="BreadCrumbs">
            <div className="BreadCrumbs-left">
              <span className="BreadCrumbs-title">Campaign Management</span>
              <span className="BreadCrumbs-campaign-name">
                {this.props.campaign.name}
              </span>
            </div>
            <div className="BreadCrumbs-right">
              {this.props.campaign.partner.name}
            </div>
          </div>
        </Box>
      </Flex>
    );
  }
}

interface StateProps {
  campaign: CampaignProps;
  user: any;
}

interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  campaign: state.campaign.data,
  user: state.app.user
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BreadCrumbs);
