import React from "react";
import { compose, withProps, withHandlers } from "recompose";

import {
  GoogleMap,
  Marker,
  withGoogleMap,
  withScriptjs
} from "react-google-maps";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";

import { environment, mapStyleLight } from "../../config";
// import clusterBgImg from "../../assets/img/cluster-bg.png";

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${environment.googleApiKey}`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  const markers = props.markers.map((marker, index) => {
    return (
      <Marker
        key={index}
        position={{ lat: marker.lat, lng: marker.lng }}
        onClick={marker.onClick}
        options={{ user: marker.user }}
        icon={
          "https://mt.google.com/vt/icon/name=icons/spotlight/spotlight-ad.png"
        }
      />
    );
  });

  return (
    <GoogleMap
      defaultZoom={11}
      defaultCenter={props.defaultCenter}
      options={{ styles: mapStyleLight, minZoom: 3 }}
    >
      <MarkerClusterer
        onClick={props.onClusterClick.bind(this)}
        averageCenter
        enableRetinaIcons
      >
        {markers}
      </MarkerClusterer>
    </GoogleMap>
  );
});

export { Map };
