import moment from "moment";
import { environment } from "../config";

export const parseStringAsDate = () => {
  return null;
};

export const daysBetweenDates = (firstDate: Date, secondDate: Date) => {
  return Math.round(
    Math.abs(
      (firstDate.getTime() - secondDate.getTime()) / (24 * 60 * 60 * 1000)
    )
  );
};

export const languageCodeToName = (code: string) => {
  if (code === "en") {
    return "English";
  } else if (code === "es") {
    return "Spanish";
  } else {
    return "Other";
  }
};

export const staticMapUrl = (latitude: number, longitude: number) => {
  return `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=13&size=600x300&maptype=terrain&style=element:geometry%7Ccolor:0xf5f5f5&amp;style=element:labels.icon%7Cvisibility:off&amp;style=element:labels.text.fill%7Ccolor:0x616161&amp;style=element:labels.text.stroke%7Ccolor:0xf5f5f5&amp;style=feature:administrative.land_parcel%7Celement:labels.text.fill%7Ccolor:0xbdbdbd&amp;style=feature:poi%7Celement:geometry%7Ccolor:0xeeeeee&amp;style=feature:poi%7Celement:labels.text.fill%7Ccolor:0x757575&amp;style=feature:poi.park%7Celement:geometry%7Ccolor:0xe5e5e5&amp;style=feature:poi.park%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&amp;style=feature:road%7Celement:geometry%7Ccolor:0xffffff&amp;style=feature:road.arterial%7Celement:labels.text.fill%7Ccolor:0x757575&amp;style=feature:road.highway%7Celement:geometry%7Ccolor:0xdadada&amp;style=feature:road.highway%7Celement:labels.text.fill%7Ccolor:0x616161&amp;style=feature:road.local%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&amp;style=feature:transit.line%7Celement:geometry%7Ccolor:0xe5e5e5&amp;style=feature:transit.station%7Celement:geometry%7Ccolor:0xeeeeee&amp;style=feature:water%7Celement:geometry%7Ccolor:0xc9c9c9&amp;style=feature:water%7Celement:labels.text.fill%7Ccolor:0x9e9e9e&key=${environment.googleApiKey}`;
};

// Group by time period - By 'day' | 'week' | 'month' | 'year'
// ------------------------------------------------------------
export const groupByDay = (entries: any[]) => {
  let series: any = [];
  let labels: any = [];

  let groups: any = {};

  for (let entry of entries) {
    let date = entry.created_at ? new Date(entry.created_at) : new Date();

    let key = moment(date).format("L");
    groups[key] = groups[key] ? groups[key] + 1 : 1;
  }

  Object.keys(groups).forEach(key => {
    series.push({ x: key, y: groups[key] });
    labels.push(key);
  });

  return {
    labels: labels,
    series: series
  };
};
