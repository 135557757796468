import React from "react";
import { Route, Redirect } from "react-router-dom";

const PrivateRoute = ({ authorized, ...props }) => {
  const uuid = props.computedMatch.params.uuid;
  const redirectUrl = uuid ? `/${uuid}/login` : "/";
  return authorized ? <Route {...props} /> : <Redirect to={redirectUrl} />;
};

export default PrivateRoute;
