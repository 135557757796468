import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";

import * as Redux from "redux";
import { connect } from "react-redux";

import AuthorizedRoute from "./routing/AuthorizedRoute";
import NonAuthorizedRoute from "./routing/NonAuthorizedRoute";

import Header from "./components/partials/Header";
import Nav from "./components/partials/Nav";
import Footer from "./components/partials/Footer";
import BreadCrumbs from "./components/partials/BreadCrumbs";

import LoginScreen from "./components/screens/LoginScreen";
import HomeScreen from "./components/screens/HomeScreen";
import StoryListScreen from "./components/screens/StoryListScreen";
import MapScreen from "./components/screens/MapScreen";

interface Props {
  isLoggedIn: boolean;
}

class AppContent extends React.Component<Props> {
  routes() {
    return (
      <Switch>
        <NonAuthorizedRoute
          path="/:uuid/login"
          component={LoginScreen}
          authorized={this.props.isLoggedIn}
        />
        <AuthorizedRoute
          path="/:uuid/home"
          component={HomeScreen}
          authorized={this.props.isLoggedIn}
        />
        <AuthorizedRoute
          path="/:uuid/stories"
          component={StoryListScreen}
          authorized={this.props.isLoggedIn}
        />
        <AuthorizedRoute
          path="/:uuid/map"
          component={MapScreen}
          authorized={this.props.isLoggedIn}
        />

        <AuthorizedRoute
          path="/:uuid"
          component={MapScreen}
          authorized={false}
        />
      </Switch>
    );
  }

  renderBreadCrumbs() {
    if (!this.props.isLoggedIn) {
      return null;
    }
    return <BreadCrumbs />;
  }

  render() {
    return (
      <div className="App">
        <Router>
          <Header />
          <Nav />
          <div className="content">
            {this.renderBreadCrumbs()}
            {this.routes()}
          </div>
          <Footer />
        </Router>
      </div>
    );
  }
}

interface DispatchProps {}

const mapStateToProps = (state: any) => ({
  isLoggedIn: state.app.isLoggedIn
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators({}, dispatch)
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AppContent);
