import React from "react";

interface Props {
  placeholder: string;
  secure: boolean;
  initialValue: string;
  onChange(value: string): any;
  onKeyPress(event: any): any;
}

interface State {
  value: string;
}

class TextInput extends React.Component<Props, State> {
  static defaultProps = {
    placeholder: "",
    secure: false,
    initialValue: "",
    onChange: () => {},
    onKeyPress: () => {}
  };

  componentWillMount() {
    this.setState({ value: this.props.initialValue });
  }

  state = {
    value: ""
  };

  handleChange(event: React.FormEvent<HTMLInputElement>) {
    const value: string = event.currentTarget.value;
    this.setState({ value: value });
    this.props.onChange(value);
  }
  handleKeyPress(event: React.FormEvent<HTMLInputElement>) {
    this.props.onKeyPress(event);
  }

  render() {
    return (
      <div className="TextInput">
        <input
          placeholder={this.props.placeholder}
          type={this.props.secure ? "password" : "text"}
          value={this.state.value}
          onChange={this.handleChange.bind(this)}
          onKeyPress={this.handleKeyPress.bind(this)}
        />
      </div>
    );
  }
}

export { TextInput };
