const ENV = "prod"

const defaults = {
  baseUrl: "https://service.storyplacesocial.com/",
  googleApiKey: "AIzaSyC3b5jJzZLZvHDAILXzDGFRoK8_1YWhf58",
  cacheTTL: 1000 * 60 * 10, // 10 minutes
}

const environments = {
  dev: {
    cacheTTL: 5000,
    permalinkPasscode: "UHRQGUfrDJFCUiTEoVN4EnC7dZqr8NrB",
  },
  stage: {
    cacheTTL: 1000,
    baseUrl: "https://service.storyplacesocial.com/",
    permalinkPasscode: "kD9jF7yvaQ3aeveJGbkHw82dCTYjoLqH",
  },
  prod: {
    baseUrl: "https://service.storyplacesocial.com/",
    permalinkPasscode: "vbzqdoduVewqavfi6uokxbWYroMiKTBB",
  },
}

const environment = { ...defaults, ...environments[ENV] }

export { environment }
