import React from "react";

interface Props {
  title: string;
  subtitle: string;
  footer: string;
  fillHeight: boolean;
}

class WidgetContainer extends React.Component<Props> {
  static defaultProps = {
    title: null,
    subtitle: null,
    footer: null,
    fillHeight: true
  };

  renderTitle() {
    if (this.props.title) {
      return <div className="WidgetContainer-title">{this.props.title}</div>;
    }
    return null;
  }

  renderSubtitle() {
    if (this.props.subtitle) {
      return (
        <div className="WidgetContainer-subtitle">{this.props.subtitle}</div>
      );
    }
    return null;
  }

  renderFooter() {
    if (this.props.footer) {
      return (
        <>
          <hr />
          <div className="WidgetContainer-footer">{this.props.footer}</div>
        </>
      );
    }
    return null;
  }

  render() {
    return (
      <div
        className={`WidgetContainer ${
          this.props.fillHeight ? "fill-height" : ""
        }`}
      >
        {this.renderTitle()}
        {this.renderSubtitle()}
        <div className="WidgetContainer-content">{this.props.children}</div>
        {this.renderFooter()}
      </div>
    );
  }
}

export { WidgetContainer };
