import axios, { AxiosRequestConfig } from "axios";

import { environment } from "../config";

const campaignDataStub = {
  title: "Test Campaign",
  stats: [
    { key: "Status", value: "Active" },
    { key: "Views", value: "12,342" },
    { key: "Likes", value: "4,255" },
    { key: "Bookmarks", value: "381" },
    { key: "Comments", value: "544" },
    { key: "From", value: "28/4/2019" },
    { key: "To", value: "28/5/2019" }
  ],
  stories: [
    {
      id: 1,
      title: "This awesome story",
      username: "thestoryteller",
      views: 1024,
      likes: 433,
      bookmarks: 24,
      url: "https://google.com"
    },
    {
      id: 2,
      title: "This cool story",
      username: "thestoryteller1",
      views: 1326,
      likes: 321,
      bookmarks: 115,
      url: "https://google.com"
    }
  ]
};

export const INITIAL_STATE = {
  retrieved: false,
  error: false,
  timestamp: null,
  data: null
};

export const FETCH_CAMPAIGN_START = "CampaignState/FETCH_CAMPAIGN_START";
export const FETCH_CAMPAIGN_SUCCESS = "CampaignState/FETCH_CAMPAIGN_SUCCESS";
export const FETCH_CAMPAIGN_FAILED = "CampaignState/FETCH_CAMPAIGN_FAILED";
export const FETCH_CAMPAIGN_CACHED = "CampaignState/FETCH_CAMPAIGN_CACHED";

export const fetchCampaign = (
  uuid: string,
  password: string,
  forceRefresh: boolean = false
) => {
  return (dispatch: any, getState: any) => {
    //loader

    const { retrieved, timestamp } = getState().campaign;

    const expired = retrieved
      ? Date.now() > timestamp + environment.cacheTTL
      : false;

    if (retrieved && !forceRefresh && !expired) {
      dispatch({ type: FETCH_CAMPAIGN_CACHED });
    } else {
      // call api

      const url = `${environment.baseUrl}api/sponsor/campaign/${uuid}/?password=${password}`;

      axios
        .get(url)
        .then(response => {
          console.log("response", response);
          dispatch({ type: FETCH_CAMPAIGN_SUCCESS, payload: response.data });
        })
        .catch(error => {
          console.log("error", error);
          dispatch({ type: FETCH_CAMPAIGN_FAILED });
        });
    }
  };
};

export default function CampaignStateReducer(
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case FETCH_CAMPAIGN_START:
      return {
        ...state
      };
    case FETCH_CAMPAIGN_SUCCESS:
      return {
        ...state,
        data: action.payload,
        retrieved: true,
        timestamp: Date.now()
      };
    case FETCH_CAMPAIGN_CACHED:
      return state;
    case FETCH_CAMPAIGN_FAILED:
      return {
        ...state,
        data: {},
        retrieved: false,
        error: true
      };
    default:
      return state;
  }
}
