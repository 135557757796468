import React from "react";
import * as Redux from "redux";
import { connect } from "react-redux";

import { Flex, Box } from "@rebass/grid";

import { CampaignProps, StoryProps, TagProps } from "../../state/Props";
import { fetchCampaign } from "../../state/CampaignState";
import { NavProps } from "../../state/Props";
import { WidgetContainer, Table } from "../common";
import { setUuid } from "../../state/AppState";
import ReactWordcloud, { Word } from "react-wordcloud";

import { daysBetweenDates } from "../../services/Utils";

import { colors } from "../../config";
import { BasicChart, PieChart } from "../common";

class HomeScreen extends React.Component<Props> {
  componentWillMount() {
    const uuid = this.props.match.params.uuid;
    this.props.setUuid(uuid);

    this.props.fetchCampaign(uuid, this.props.password);
  }

  renderStatsList() {
    const { campaign } = this.props;
    const { metrics } = campaign;

    const start_date_time = new Date(campaign.start_date);
    const end_date_time = new Date(campaign.end_date);
    const now = new Date();

    let status = "";
    if (now > end_date_time) {
      status = "Completed";
    } else if (now < start_date_time) {
      status = "Upcoming";
    } else {
      status = "Active";
    }

    const stats = [
      { key: "Status", value: status },
      { key: "Views", value: metrics.views },
      { key: "Likes", value: metrics.likes },
      { key: "Bookmarks", value: metrics.bookmarks },
      { key: "Comments", value: metrics.comments },
      { key: "From", value: campaign.start_date },
      { key: "To", value: campaign.end_date }
    ];

    return stats.map((item, index) => {
      return (
        <Flex className="listItem" key={index}>
          <Box className="listItemName" width={1 / 2} px={2}>
            {item.key}:
          </Box>
          <Box className="listItemValue" width={1 / 2} px={2}>
            {item.value}
          </Box>
        </Flex>
      );
    });
  }

  renderChart(title: string, data: any) {
    return (
      <BasicChart
        title={title}
        fillColor={colors.primary}
        borderColor={"white"}
        entries={data}
      />
    );
  }

  renderPieChart(
    data: any,
    keys: string[],
    labels: string[],
    colors: string[]
  ) {
    return <PieChart data={data} keys={keys} labels={labels} colors={colors} />;
  }

  renderTagContainer(
    tagTableHeaders: string[],
    tagTableRows: any[],
    tagData: any,
    tagOptions: any
  ) {
    if (!tagData || tagData.length < 1) {
      return (
        <WidgetContainer
          title="Tags"
          subtitle="No Tags Found"
        ></WidgetContainer>
      );
    }
    return (
      <WidgetContainer title="Tags">
        <Flex>
          <Box width={1 / 2}>
            <Table
              headers={tagTableHeaders}
              rows={tagTableRows}
              maxHeight={300}
            ></Table>
          </Box>
          <Box width={1 / 2}>
            <ReactWordcloud
              words={tagData}
              options={tagOptions}
            ></ReactWordcloud>
          </Box>
        </Flex>
      </WidgetContainer>
    );
  }

  render() {
    if (!this.props.campaign) {
      return null;
    }

    const { campaign } = this.props;

    const start_date_time = new Date(campaign.start_date);
    const end_date_time = new Date(campaign.end_date);
    const now = new Date();

    let footerText = "";
    let days = 0;
    if (now > end_date_time) {
      days = daysBetweenDates(now, end_date_time);
      footerText = `Campaign ended ${days} day${days === 1 ? "" : "s"} ago.`;
    } else if (now < start_date_time) {
      days = daysBetweenDates(now, start_date_time);
      footerText = `Campaign will start in ${days} day${
        days === 1 ? "" : "s"
      }.`;
    } else {
      days = daysBetweenDates(now, end_date_time);
      footerText = `Campaign will end in ${days} day${days === 1 ? "" : "s"}.`;
    }

    const languageData: any = {
      en: 0,
      es: 0,
      total: 0
    };

    const tags: any = {};
    for (let story of this.props.campaign.collection.stories) {
      languageData[story.language] += 1 || 0;
      languageData.total += 1;

      if (story.tags && story.tags.length) {
        for (let tag of story.tags) {
          if (tags[tag.name]) {
            tags[tag.name] += 1;
          } else {
            tags[tag.name] = 1;
          }
        }
      }
    }

    const tagData: Word[] = [];
    for (let key of Object.keys(tags)) {
      const tag: Word = { text: `#${key}`, value: tags[key] };
      tagData.push(tag);
    }

    const tagTableHeaders = ["Rank", "Tag", "Count"];
    let tagTableRows = [];

    for (let w of tagData) {
      const row = [w.text, w.value];
      tagTableRows.push(row);
    }

    tagTableRows.sort((a, b) => {
      return a[1] >= b[1] ? -1 : 1;
    });

    tagTableRows = tagTableRows.map((r: any, index) => {
      let row = r;
      row.unshift(index + 1);
      return row;
    });

    const tagOptions: any = {
      colors: colors.dataColors,
      fontFamily: "Poppins",
      fontSizes: [16, 32, 48],
      fontWeight: "bold",
      // deterministic: true,
      rotations: 3,
      padding: 1,
      rotationAngles: [360, 90, 270]
    };

    return (
      <div className="screen HomeScreen">
        <Flex>
          <Box width={[1 / 2, 1 / 4]} px={2}>
            <WidgetContainer
              title={this.props.campaign.name}
              subtitle="Campaign Stats"
              footer={footerText}
            >
              <Flex>
                <Box width={1 / 2} px={2}>
                  <div className="label">Total Views</div>
                  <h3>{campaign.metrics.views}</h3>
                </Box>
                <Box width={1 / 2} px={2}>
                  <div className="label">Total Stories</div>
                  <h3>{campaign.metrics.stories}</h3>
                </Box>
              </Flex>
              <hr />

              {this.renderStatsList()}
            </WidgetContainer>
          </Box>
          <Box width={[1 / 2, 3 / 4]} px={0}>
            <Flex>
              <Box width={1} px={2}>
                <WidgetContainer title="Stories Created">
                  {this.renderChart(
                    "Stories",
                    this.props.campaign.collection.stories.reverse()
                  )}
                </WidgetContainer>
              </Box>
            </Flex>
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 2} px={2}>
            <WidgetContainer title="Languages">
              {this.renderPieChart(
                languageData,
                ["en", "es"],
                ["English", "Spanish"],
                colors.dataColors
              )}
            </WidgetContainer>
          </Box>
          <Box width={1 / 2} px={2}>
            {this.renderTagContainer(
              tagTableHeaders,
              tagTableRows,
              tagData,
              tagOptions
            )}
          </Box>
        </Flex>
        <Flex>
          <Box width={1 / 3} px={2}>
            <WidgetContainer title="Likes by Date">
              {this.renderChart("Likes", campaign.likes)}
            </WidgetContainer>
          </Box>
          <Box width={1 / 3} px={2}>
            <WidgetContainer title="Bookmarks by Date">
              {this.renderChart("Bookmarks", campaign.bookmarks)}
            </WidgetContainer>
          </Box>

          <Box width={1 / 3} px={2}>
            <WidgetContainer title="Views by Date">
              {this.renderChart("Views", campaign.views)}
            </WidgetContainer>
          </Box>
        </Flex>
      </div>
    );
  }
}

interface StateProps {
  campaign: CampaignProps;
  password: string;
}

interface DispatchProps {
  fetchCampaign: typeof fetchCampaign;
  setUuid: typeof setUuid;
}

interface Props extends StateProps, DispatchProps, NavProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  campaign: state.campaign.data,
  password: state.app.password
});

const mapDispatchToProps = (dispatch: Redux.Dispatch): DispatchProps => ({
  ...Redux.bindActionCreators(
    {
      fetchCampaign,
      setUuid
    },
    dispatch
  )
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HomeScreen);
