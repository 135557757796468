const colors = {
  white: "#fff",
  primary: "#7b3cbf",
  chart1: "turquoise",
  chart2: "pink",
  error: "rgb(220,0,0)",
  dataColors: ["#7B3CBF", "#E8646F", "#FFBF7A", "#E8DC64", "#7EFFCB"]
};

export { colors };
