import React from "react";
import { Bar, Pie } from "react-chartjs-2";

import { groupByDay } from "../../services/Utils";

interface BasicChartProps {
  title: string;
  entries: any[];
  fillColor: string;
  borderColor: string;
  showLegend: boolean;
}

class BasicChart extends React.Component<BasicChartProps> {
  static defaultProps = {
    title: "",
    fillColor: "#7b3cbf",
    borderColor: "#000000",
    entries: [],
    showLegend: true
  };

  render() {
    const { series, labels } = groupByDay(this.props.entries);

    const data = {
      labels: labels,
      datasets: [
        {
          backgroundColor: this.props.fillColor,
          borderColor: this.props.borderColor,
          borderWidth: 1,
          data: series,
          label: this.props.title
        }
      ]
    };

    const options = {
      legend: { display: this.props.showLegend },
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              precision: 0
            }
          }
        ]
      }
    };
    return <Bar data={data} options={options} />;
  }
}

interface MultiChartProps {
  title: string;
  datasets: any[];
}

class MultiChart extends React.Component<MultiChartProps> {
  static defaultProps = {
    title: "",
    labels: [],
    datasets: []
  };

  render() {
    const datasets = [];
    const labels = [];
    for (let set of this.props.datasets) {
      const item = groupByDay(set);
      const series = item.series;
      labels.push(item.labels);

      const d = {
        data: series
      };
      datasets.push(d);
    }

    const data = {
      datasets: datasets,
      labels: labels
    };
    return <Bar data={data} />;
  }
}

interface PieChartProps {
  data: any;
  keys: string[];
  labels: string[];
  colors: string[];
}

class PieChart extends React.Component<PieChartProps> {
  static defaultProps = {
    data: {},
    keys: [],
    labels: []
  };

  render() {
    const totalRecords = this.props.data["total"];
    const chartData = {
      labels: this.props.labels,
      datasets: [
        {
          data: this.props.keys.map(k => {
            return this.props.data[k];
          }),
          backgroundColor: this.props.colors
        }
      ]
    };

    const chartOptions = {
      tooltips: {
        callbacks: {
          label: function(tooltipItem: any, data: any) {
            const value = data.datasets[0].data[tooltipItem.index];
            if (totalRecords) {
              const percent = Math.round(
                (parseInt(value) / totalRecords) * 100
              );
              return `${value} (${percent}%)`;
            }
            return value;
          },
          title: function(tooltipItem: any, data: any) {
            return data.labels[tooltipItem[0].index];
          }
        }
      }
    };

    return <Pie data={chartData} options={chartOptions}></Pie>;
  }
}

export { BasicChart, MultiChart, PieChart };
