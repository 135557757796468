import React from "react";
import { connect } from "react-redux";

import { CampaignProps } from "../../state/Props";

import logo from "../../assets/img/logo.png";
import brand from "../../assets/img/placeholders/brand.png";
// import brandwide from "../../assets/img/placeholders/brandwide.png";

class Header extends React.Component<Props> {
  renderBrand() {
    if (!this.props.isLoggedIn) {
      return null;
    }

    if (!this.props.campaign) {
      return null;
    }

    const brandImageUrl = this.props.campaign.partner.image;
    if (brandImageUrl) {
      return <img className="brand" alt="Brand Logo" src={brandImageUrl} />;
    }

    return null;
  }

  render() {
    return (
      <header className="Header">
        <a
          href="#"
          className="logo"
          style={{ backgroundImage: `url(${logo})` }}
        >
          StoryPlace
        </a>
        {this.renderBrand()}
      </header>
    );
  }
}

interface StateProps {
  isLoggedIn: boolean;
  campaign: CampaignProps;
}

interface DispatchProps {}

interface Props extends StateProps, DispatchProps {}

const mapStateToProps = (state: any, ownProps: any) => ({
  ...ownProps,
  isLoggedIn: state.app.isLoggedIn,
  campaign: state.campaign.data
});

export default connect(
  mapStateToProps,
  null
)(Header);
