import { createStore, applyMiddleware, compose } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";

import rootReducer from "../redux/reducer";

const enhancers = [
  applyMiddleware(
    thunkMiddleware,
    createLogger({
      collapsed: true
    })
  )
];

const enhancer: any = compose(...enhancers);

const persistConfig = {
  key: "root",
  storage
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
let store = createStore(persistedReducer, {}, enhancer);
let persistor = persistStore(store);

//temp (remove eventually)
persistor.purge();

export { store, persistor };
