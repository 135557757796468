import axios, { AxiosRequestConfig } from "axios";
import { environment } from "../config";

const INITIAL_STATE = {
  isLoggedIn: false,
  user: null,
  uuid: null,
  errorMessage: null
};

export const LOGIN_START = "AppState/LOGIN_START";
export const LOGIN_SUCCESS = "AppState/LOGIN_SUCCESS";
export const LOGIN_FAIL = "AppState/LOGIN_FAIL";

export const LOGOUT = "AppState/LOGOUT";

export const SET_UUID = "AppState/SET_UUID";

export const login = (uuid: string, password: string) => {
  return (dispatch: any) => {
    dispatch({ type: LOGIN_START });

    const url = `${environment.baseUrl}api/sponsor/campaign/${uuid}/?password=${password}`;

    axios
      .get(url)
      .then(response => {
        console.log("response", response);
        dispatch({ type: LOGIN_SUCCESS, payload: password });

        const obj = {
          uuid: uuid,
          password: password
        };
        window.localStorage.setItem("store", JSON.stringify(obj));
      })
      .catch(error => {
        console.log("error", error);
        dispatch({ type: LOGIN_FAIL });
        window.localStorage.setItem("store", "");
      });

    //loader
    //api
  };
};

export const logout = () => {
  return (dispatch: any) => {
    dispatch({ type: LOGOUT });
    window.localStorage.setItem("store", "");
  };
};

export const setUuid = (uuid: string) => {
  return (dispatch: any) => {
    dispatch({ type: SET_UUID, payload: uuid });
  };
};

export default function AppStateReducer(
  state = INITIAL_STATE,
  action: { type: string; payload: any }
) {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        errorMessage: null,
        password: action.payload
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        uuid: null,
        errorMessage: null
      };
    case SET_UUID:
      return {
        ...state,
        uuid: action.payload,
        errorMessage: null
      };
    case LOGIN_FAIL:
      return {
        ...state,
        errorMessage: "Could not log you in with that password."
      };

    default:
      return state;
  }
}
